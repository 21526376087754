<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      state: 1,
      isMobile: false
    }
  },
  mounted() {
    this.isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
    if (this.isMobile) {
      this.setRem()
      window.location.href = 'http://jjrtjjzs.com/mobile'
    } else {
    }
    window.onresize = () => {
      return (() => {
        this.isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
        if (this.isMobile) {
          this.setRem()
          window.location.href = 'http://jjrtjjzs.com/mobile'
        }
      })()
    };
  },
  watch(val, old) {
    if (val == true) {
      this.setRem()
      window.location.href = 'http://jjrtjjzs.com/mobile'
    } else {
    }
  },
  methods: {
    setRem() {
      const scale = document.documentElement.clientWidth / 375 * 100; document.documentElement.style.fontSize = scale + 'px';
    },
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #f7f7f7;
}
</style>

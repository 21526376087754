import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/style/common.less'
/* el */
import ElementUI from 'element-ui';
import '../node_modules/element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
/* vue-seamless-scroll  列表滚动*/
import vueSeamlessScroll from "vue-seamless-scroll";
Vue.component('vueSeamlessScroll', vueSeamlessScroll)
/* echarts */
import * as echarts from 'echarts'

/* swiper */
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'

/* 数字滚动 */
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/index',
    component: () => import('@/views/index.vue'),
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/home/index.vue'),
        meta: { title: '首页' },
      },
      {
        path: '/data',
        name: 'data',
        component: () => import('@/views/data/index.vue'),
        meta: { title: '指数数据' }
      },
      {
        path: '/data/:id',
        name: 'data',
        component: () => import('@/views/data/index.vue'),
        meta: { title: '指数数据' }
      },
      {
        path: '/comment',
        name: 'comment',
        component: () => import('@/views/comment/index.vue'),
        meta: { title: '指数点评' }
      },
      {
        path: '/comment/:id',
        name: 'comment',
        component: () => import('@/views/comment/detail.vue'),
        meta: { title: '指数点评' }
      },
      {
        path: '/introduce',
        name: 'enter',
        component: () => import('@/views/introduce/index.vue'),
        meta: { title: '指数介绍' }
      },
      {
        path: '/enter',
        name: 'enter',
        component: () => import('@/views/enter/index.vue'),
        meta: { title: '优质企业' }
      },
      {
        path: '/enter/:id',
        name: 'enter',
        component: () => import('@/views/enter/detail.vue'),
        meta: { title: '优质企业' }
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('@/views/news/index.vue'),
        meta: { title: '新闻资讯' }
      },
      {
        path: '/news/:id',
        name: 'news',
        component: () => import('@/views/news/detail.vue'),
        meta: { title: '新闻资讯' }
      },
      {
        path: '/standard',
        name: 'standard',
        component: () => import('@/views/standard/index.vue'),
        meta: { title: '家具标准' }
      },
      {
        path: '/standard/:id',
        name: 'standardDetail',
        component: () => import('@/views/standard/detail.vue'),
        meta: { title: '家具标准' }
      },
      {
        path: '/issue',
        name: 'issue',
        component: () => import('@/views/issue/index.vue'),
        meta: { title: '常见问题' }
      },
      {
        path: '/issue/:id',
        name: 'issueDetail',
        component: () => import('@/views/issue/detail.vue'),
        meta: { title: '常见问题' }
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about/index.vue'),
        meta: { title: '关于我们' }
      },
      {
        path: '/phone',
        name: 'phone',
        component: () => import('@/views/phone/index.vue'),
        meta: { title: '联系我们' }
      },
      {
        path: '/information',
        name: 'information',
        component: () => import('@/views/information/index.vue'),
        meta: { title: '指数留言板' }
      },
    ]
  },

]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  document.body.scrollIntoView()
  next()
})
export default router
